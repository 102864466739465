import DataForm from '../../components/users/CreateEditForm.vue'
import PermissionList from '../../components/users/PermissionList.vue'
import ResetPass from '../../components/users/ResetPass.vue'


export default {
  components: {
    DataForm,
    PermissionList,
    ResetPass
  },
  data: () => ({
    flags: {
      creating: false,
      isValidDataForm: false
    },
    userID: null,
    permissions: {
      read: true,
      write: true
    }
  }),
  created() {
    this.flags.creating = (this.$route.name == 'user-create')
    if (!this.flags.creating) {
      this.userID = this.$route.params.id
      this.getData()
    }
    this.permissions = this.$getPermissions()
  },
  computed: {
    sending() {
      const sending = this.$store.getters['user/getSending']
      return sending
    }
  },
  watch: {
    '$route.name': function (value) {
      if (value == 'user-create') {
        this.flags.creating = true
        this.$store.dispatch('user/clear', 'view')
      }
    }
  },
  methods: {
    async save() {
      this.$refs.dataForm.validate()
      if (!this.flags.isValidDataForm) return false

      if (this.flags.creating) {
        let content, type
        let result = await this.$store.dispatch('user/create')
        if (!result) {
          content = this.$t('alerts.userEmailExists')
          type = "error"
        } else {
          content = this.$t('alerts.userSuccess')
          type = "success"
        }
        this.$store.commit('snackbar/show', {
          content: content,
          type: type,
        })
      } else {
        await this.$store.dispatch('user/edit')
        this.$store.commit('snackbar/show', {
          content: this.$t('alerts.userSuccessEdit'),
          type: "success",
        })
      }
      this.$router.push({
        name: 'user-list'
      })
    },
    async getData() {
      await this.$store.dispatch('user/get', this.userID)
    },
    async cancel() {
      this.$router.push({
        name: 'user-list'
      })
    },
    async remove() {
      var response = await this.$store.dispatch("dialog/show", {
        title: this.$t('labels.remove'),
        content: this.$t('alerts.userRemove'),
      })
      if (response) {
        await this.$store.dispatch('user/remove')
        this.$router.push({
          name: 'user-list'
        })
      }
    }
  },
  destroyed() {
    this.$store.dispatch('user/clear', 'view')
  }
}
