export default {
  data: () => ({
    permissions: {
      read: true,
      write: true,
    },
  }),
  created() {
    if (this.$route.name == "user-create") {
      this.setCleanPermissions();
    }
    this.permissions = this.$getPermissions();
  },
  watch: {
    "$route.name": function (value) {
      if (value == "user-create") {
        this.setCleanPermissions();
      }
    },
    // desabilita permissões não acessiveis para distribuidores
    "user.fromClient": function (value) {
      if (value) {
        const forDisable = this.$store.state.auth.permissions.filter(
          (item) => item.hideOnDistributor
        ),
          forDisableArr = forDisable.map((item) => item.route);
        for (var perm in this.user.permissions) {
          if (forDisableArr.includes(this.user.permissions[perm].route)) {
            this.user.permissions[perm].read = false;
            this.user.permissions[perm].write = false;
          }
        }
      }
    },
  },
  computed: {
    user() {
      return this.$store.state.user.view;
    },
  },
  methods: {
    setCleanPermissions() {
      this.user.permissions = JSON.parse(
        JSON.stringify(this.$store.state.auth.permissions)
      );
      this.user.permissions.forEach((item) => {
        item.read = true;
        item.write = true;
      });
    },
    logicCheck(route, action) {
      var permissions = this.user.permissions;
      permissions.forEach((item) => {
        if (item.route == route) {
          if (action == "write") {
            if (!item.read) item.read = true;
          }
          if (action == "read") {
            if (item.write) item.write = false;
          }
        }
      });
      this.user.permissions = permissions;
    },
    // garante que as permissões exibidas sejam adequadas para cada tipo de perfil ( admin/distribuidor )
    shouldShow(route) {
      const perm = this.$store.state.auth.permissions.filter(
        (item) => item.route == route
      )[0];
      return !(this.user.fromClient && perm.hideOnDistributor);
    },
  },
};
