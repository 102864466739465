import validatorRules from "../../helpers/validator_rules";

export default {
  props: ["value"],
  data: () => ({
    _value: false,
    flags: {
      creating: false,
      isValid: false,
      isAdmin: false,
    },
    permissions: {
      read: true,
      write: true,
    },
  }),
  computed: {
    user() {
      return this.$store.state.user.view;
    },
    distributors() {
      return this.$store.state.distributor.list;
    },
    jobRoles() {
      const translatedjobRoles = this.$store.state.jobRoles.list.map(
        (jobRole) => ({
          id: jobRole.id,
          role: jobRole.role,
        })
      );
      return translatedjobRoles;
    },
    languages() {
      let langs = this.$store.state.system.langs.map((lang, index) => {
        return {
          id: index + 1,
          lang: this.$t(lang),
        };
      })

      console.log(langs)
      return langs;
    },
    email() {
      return validatorRules.email(this.$t("validation.email"));
    },
    required() {
      return validatorRules.required(this.$t("validation.required"));
    },
  },
  watch: {
    user(val) {
      if (val.distributor && typeof val.fromClient == "undefined") {
        this.user.fromClient = true;
      }
    },
    "$route.name": function (value) {
      if (value == "user-create") {
        setTimeout(
          function () {
            this.flags.creating = true;
          }.bind(this),
          400
        );
      }
    },
  },
  created() {
    this.language = localStorage.getItem("currentLang");
    this._value = this.value;
    this.flags.creating = this.$route.name == "user-create";
    this.getFormData();
    this.permissions = this.$getPermissions();
    this.checkAdmin();
  },
  methods: {
    checkAdmin() {
      const user = this.$store.state.auth.user;
      // is not an admin
      if (user.distributorID) {
        this.flags.isAdmin = false;
        this.$store.state.user.view.fromClient = true;
        this.$store.state.user.view.distributor = user.distributorID;
      } else {
        this.flags.isAdmin = true;
      }
    },
    validate() {
      this.$refs.form.validate();
      this._value = this.flags.isValid;

      if (this.flags.isValid) {
        this.$emit("input", true);
      } else {
        this.$emit("input", false);
      }
    },
    async getFormData() {
      await this.$store.dispatch("distributor/list");
      await this.$store.dispatch("jobRoles/list");
    },
  },
};
